
.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar .brand{
  flex: 1;
  color: #000;
  font-size: 28px;
  text-decoration: none;
  padding: 30px 0px;
  font-weight: 500;

  transition: color 0.1s;
}

.navbar .brand:hover, .navbar .brand.active{
  color: #000;
}

.navbar .option{
  font-size: 21px;
  color: #000;
  text-decoration: none;
  padding: 10px;
  margin-left: 50px;
  font-weight: 500;

  transition: color 0.1s;
}

.navbar .option:hover, .navbar .option.active {
  color: #ff0000;
}

.navbar.phone {
  display: none;
}

@media screen and (max-width: 900px) {
  .navbar.monitor{
    display: none;
  }

  .navbar.phone {
    display: block;
    flex-direction: column;
  }

  .navbar .burger {
    height: 60px;
    cursor: pointer;
  }

  .navbar .alwaysVisible {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .navbar .options {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 100px;
    box-sizing: border-box;
    padding: 6px 10px 6px 10px;
    left: 0px;
    background-color: white;
    width: 100%;
    z-index: 9999999;
  }

  .navbar .options.visible {
    display: flex;
  }

  .navbar .option {
    font-size: 18px;
    padding: 0px;
    margin: 6px 0px 6px 0px;
    text-align: center;
  }
}
