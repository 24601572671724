
.footer {

  font-size: 18px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.footer.soft {
  font-weight: normal;
  color: #999;
  font-size: 16px;
}

.footer .copyright {

  flex: 1;

}

.footer .socialMedia img {
  height: 40px;
  margin: 0px;
}

.footer.soft .socialMedia img {
  height: 30px;
}

