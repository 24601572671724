
.label {
  margin-top: 25px;
  margin-bottom: 5px;
  color: #888;
}

.fancyInput {
  width: 450px;
  padding: 10px;
  font-size: 18px;
  border: #ccc 1px solid;
  border-radius: 0px;
  box-shadow: 3px 3px 4px #888888;
}

.fancyArea {

  border: #ccc 1px solid;
  resize: none;
  width: 450px;
  padding: 10px;
  font-size: 16px;
  border-radius: 0px;
  box-shadow: 3px 3px 4px #888888;
  height: 200px;

}

.fancyButton {

  box-shadow: 3px 3px 4px #888888;
  border: none;
  border-radius: none;
  background-color: black;
  color: white;
  padding: 12px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 30px;

  transition: background-color 0.2s, box-shadow 0.2s;
}

.fancyButton:hover {
  background-color: #555;
  box-shadow: 5px 5px 8px #888888;
}

@media screen and (max-width: 1300px) {

  .makeBig {
    flex: 1;
  }

  .hideWhenSmall {
    display: none;
  }

}
@media screen and (max-width: 600px) {

  .fancyInput {
    width: 90%;
  }

  .fancyArea{
    width: 90%;
  }
}

.notification {

  font-size: 17px;
  color: #fff;
  padding: 10px;
  background-color: #555;
  border-radius: 5px;
  box-shadow: 3px 3px 4px #888888;
  margin-top: 10px;

}

.notification.success {
  background-color: #3c763d;
}

.notification.error {
  background-color: #d43f3a;
}
