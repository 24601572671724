

.circle {
  position: relative;
}

.circle::after {

  content: "";
  position: absolute;
  background-color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 3px;
  left: -30px;

}

.circle.yellow::after {

  background-color: rgb(255, 217, 0);

}
