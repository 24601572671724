.aboutBackground {
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  color: white;
  justify-content: center;
}

.aboutBackground .aboutTitle {

  font-size: 60px;
  font-weight: bold;
  margin-top: 150px;
  position: relative;

}

.aboutBackground .aboutTitle::after {
  content: "";
  bottom: 0px;
  left: 0px;
  height: 5px;
  width: 100px;
  background-color: red;
}

.fullSizeImage {

  width: 100%;

}

.paragraph {
  font-size: 18px;
  margin: 10px 0px 10px 0px;
  line-height: 1.8;
  text-align: justify;
}

.grayBackground {
  padding: 50px 0px 50px 0px;
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.whiteBackground {
  padding: 50px 0px 50px 0px;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paragraphTitle {
  font-size: 21px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.splitView {
  display: flex;
  flex-direction: row;
}

.splitView.reverse {
  flex-direction: row-reverse;
}

.splitView .paragraph {
  width: 50%;
  margin-right: 70px;
}

.splitView.reverse .paragraph {
  margin-left: 70px;
}

.splitView .sideImage {
  width: 50%;
}

.splitView .sideImage img {
  width: 100%;
}

@media screen and (max-width: 700px) {
  .splitView {
    flex-direction: column;
  }

  .splitView.reverse {
    flex-direction: column;
  }

  .splitView .sideImage {
    width: 100%;
  }

  .splitView .paragraph {
    width: 100%;
  }

  .splitView .paragraph {
    margin-right: 0px;
    margin-bottom: 40px;
  }
  
  .splitView.reverse .paragraph {
    margin-left: 0px;
  }
}