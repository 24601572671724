

.seriesTitleBar {
  display: flex;
  flex-direction: column;
}

.seriesTitleBar .seriesOption {
  margin-bottom: 5px;
  font-size: 18px;
  cursor: pointer;
}

.seriesImage {
  margin: 30px;
}

.allPaintings {
  display: flex;
  position: relative;
  justify-content: center;
}

.paintingImageContainer {

  margin: 20px;

}

.paintingImage .pTitle {
  color: #777;
  margin-bottom: 10px;
}

.paintingSquare {

  width: 350px;
  height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  transition: transform 0.5s;

}

.paintingSquare:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1300px) {
  .paintingImageContainer {
    margin: 4px;
  }

  .paintingSquare {

    width: 250px;
    height: 250px;
  
  }

  .paintingImage .pTitle {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .paintingImageContainer {
    margin: 2px;
  }

  .paintingSquare {

    width: 120px;
    height: 120px;
  
  }

  .paintingImage .pTitle {
    display: none;
  }
}

@media screen and (max-width: 370px) {
  .paintingSquare {

    width: 100px;
    height: 100px;
  
  }

  .paintingSquare:hover {
    transform: scale(1);
  }
}

@media screen and (max-width: 310px) {
  .paintingSquare {

    width: 80px;
    height: 80px;
  
  }
}

