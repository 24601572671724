body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pageWidth {

  width: 1200px;

}

.centered {

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;

}

.title {
  font-size: 30px;
  position: relative;
}

@media screen and (max-width: 1300px) {
  .pageWidth {
    width: 800px;
  }
}

@media screen and (max-width: 900px) {
  .pageWidth {
    width: 90%;
  }
}
