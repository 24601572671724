
.backgroundHomePage {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
}

.backgroundHomePage img {
  width: 100%;
  margin-bottom: 160px;
}

@media screen and (max-width: 700px) {
  .backgroundHomePage img {
    margin-bottom: 20px;
  }
}

.easterEgg {
  position: absolute;
  top: 3%;
  left: 3%;
  z-index: 9999;
  width: 94%;
  height: 94%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Should rotate back and forth */
.excitingText {
  font-weight: bold;
  color: #000000;
  animation: excitingText 1s infinite;
}

@keyframes excitingText {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}