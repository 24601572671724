
.paintingDetails {

  display: flex;
  position: relative;
  flex-direction: column;
  height: 80vh;
  margin: 0px 0px 40px 0px;
  align-items: center;

}

.paintingDetails .paintingDetailsImage {

  flex: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 90%;

}

@media screen and (max-width: 800px) {
    
    .paintingDetails {

        height: 70vh;
      
    }
}

.paintingDetails .paintingDetailsTitle {

  margin-top: 20px;
  font-size: 18px;

}

.paintingDetails .paintingDetailsCollection {

  font-size: 16px;

}

.paintingDetails .paintingDescription {
  margin-top: 20px;
  font-size: 16px;
}

.control-arrow:hover {
  background: rgba(0,0,0,0) !important;
}

.control-arrow::before {
  border-right-color: #000 !important;
  border-left-color: #000 !important;
}
