
.paintingBackButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 15px;
}

.paintingBackButton img{

  height: 19px;
  margin-right: 5px;
  margin-top: 1px;

}
